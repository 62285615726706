import { Injectable, Optional } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';

import { Observable, Observer } from 'rxjs/Rx';

import * as Keycloak from 'keycloak-js';
import { KeycloakService } from '../services/keycloak.service';

@Injectable()
export class KeycloakHttpInterceptor implements HttpInterceptor {

  private _urlsToIgnore: any[];

  constructor(private keycloakService: KeycloakService) {
  }

  set urlsToIgnore(value: string[] | RegExp[]) {
    this._urlsToIgnore = value;
  }

  get urlsToIgnore(): string[] | RegExp[] {
    return this._urlsToIgnore;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!this.keycloakService.authenticated()) {
      return next.handle(req);
    }

    if (this._urlsToIgnore && this._urlsToIgnore.length) {

      let hasMatch = this._urlsToIgnore.some((url) => req.url.search(url) > -1);

      if (hasMatch) {
        return next.handle(req);
      }
    }

    return this.keycloakService.getToken()
      .switchMap((token: string, index: number) => {
        return next.handle(req.clone({ setHeaders: { Authorization: `Bearer ${token}` } }));
      });

  }

}
