export const isString = function(arg: any) {
  return typeof arg === 'string';
};

export const isObject = function(arg: any) {
  return arg && typeof arg === 'object';
};

// export const cloneObject = function (arg: Object) {
//     return Object.assign({}, arg);
// };

export const cloneObject = function (arg: Object) {
    return JSON.parse(JSON.stringify(arg));
};