import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/internal/operators/catchError';
import { Observable } from 'rxjs/internal/Observable';
import { BaseService } from '../base/base-service';
import { environment } from '../environments/environment';

@Injectable()
export class OrdemServicoService extends BaseService {
    constructor(private http: HttpClient) {
        super();
    }

    getMateriaisRepavimentacao(): Observable<any> {
        return this.http.get(`${environment.OSPOA_API_URL}/materiaisRepavimentacao`)
            .pipe(
                catchError(this.handleError('getMateriaisRepavimentacao', { success: false }))
            );
    }

    getTopicosDeProdutividade(classificacao: string): Observable<any> {
        return this.http.get(`${environment.OSPOA_API_URL}/os/classificacao/${classificacao}/topicosDeProdutividade`)
            .pipe(
                catchError(this.handleError('getTopicosDeProdutividade', { success: false }))
            );
    }

    getDadosExtras(usuario, codigoOS, status?, classificacao?): Observable<any> {
        let url = `${environment.OSPOA_API_URL}/${usuario}/os/${codigoOS}?editar=obsVistoriador`;
        if (status) {
            url += `&status=${status}`;
        }
        if (classificacao) {
            url += `&classificacao=${classificacao}`;
        }
        return this.http.get(url)
            .pipe(
                catchError(this.handleError('getDadosExtras', { success: false }))
            );
    }

    getInfosPorProtocoloOuCodigo(usuario, protocoloOuCodigo): Observable<any> {
        return this.http.get(`${environment.OSPOA_API_URL}/${usuario}/os/${protocoloOuCodigo}/info`)
            .pipe(
                catchError(this.handleError('getInfosPorProtocoloOuCodigo', { success: false }))
            );
    }

    getVistoriasAntigas(usuario, protocolo): Observable<any> {
        return this.http.get(`${environment.OSPOA_API_URL}/${usuario}/os/${protocolo}/156/respostas`)
            .pipe(
                catchError(this.handleError('getVistoriasAntigas', { success: false }))
            );
    }

    getDadosOSBPM(codigoOS): Observable<any> {
        const listaVariaveis = ['nrProtocolo', 'obsVistoriador', 'obsParaVistoriador', 'obsParaExecutor', 'obsExecutor', 'obsParaReexecucao', 'obsReexecucao',
            'reexecucao', 'analiseExecucao', 'materiais', 'obsExecutorNovo', 'repavimentacao', 'materiaisRepavimentacao', 'repavimentacaoUrgente',
            'dadosRepavimentacaoRevisados', 'valoresCamposCustomizados', 'nomesCamposCustomizados', 'jaExecutada', 'servicoOutroOrgao',
            'servicoOutroOrgaoTexto', 'valoresCamposCustomizadosRevisados', 'nomesCamposCustomizadosRevisados'];
        const queryStringVariaveis = listaVariaveis.map((variavel) => {
            return 'variableList=' + variavel;
        });

        return this.http.get(`${environment.BASE_BPM_URL}/process/${codigoOS}?apikey=${environment.BASE_BPM_URL_APIKEY}&${queryStringVariaveis.join('&')}`)
            .pipe(
                catchError(this.handleError('getDadosOSBPM', null))
            );
    }

    getEnderecoFotoVistoria(numero, codigoOS) {
        const nomeFoto = `fotoVistoria${numero > 1 ? numero : ''}`;
        return `${environment.BASE_BPM_URL}/document/${codigoOS}/${nomeFoto}?apikey=${environment.BASE_BPM_URL_APIKEY}`;
    }

    getEnderecoFotoExecucao(numero, codigoOS, prefixo) {
        const nomeFoto = `foto${prefixo}Execucao${numero > 1 ? numero : ''}`;
        return `${environment.BASE_BPM_URL}/document/${codigoOS}/${nomeFoto}?apikey=${environment.BASE_BPM_URL_APIKEY}`;
    }

    getEnderecoFotoMinio(uuid, codigoOS) {
        return `${environment.OSPOA_API_URL}/os/${codigoOS}/fotos/minio/${uuid}`;
        // return `https://c75fe7e3.ngrok.io/os/${codigoOS}/fotos/minio/${uuid}?apikey=${environment.BASE_BPM_URL_APIKEY}`;
    }

    salvarObsVistoriador(usuario, codigoOS, novaObservacao): Observable<any> {
        return this.http.put(`${environment.OSPOA_API_URL}/${usuario}/os/${codigoOS}/obsVistoriador`,
            {
                novoValor: novaObservacao
            }).pipe(
                catchError(this.handleError('salvarObsVistoriador', { success: false }))
            );
    }

    concluirAnalise(usuario, dadosOS, payloadAnalise): Observable<any> {
        return this.http.post(`${environment.OSPOA_API_URL}/${usuario}/os/${dadosOS.OSCODIGO}/analisar`,
            payloadAnalise).pipe(
                catchError(this.handleError('concluirAnalise', { success: false }))
            );
    }

    enviarReexecucao(usuario, dadosOS, payloadAnalise): Observable<any> {
        return this.http.post(`${environment.OSPOA_API_URL}/${usuario}/os/${dadosOS.OSCODIGO}/analisar`,
            payloadAnalise).pipe(
                catchError(this.handleError('enviarReexecucao', { success: false }))
            );
    }

    marcarRefazerVistoria(usuario, codigoOS, observacoes): Observable<any> {
        const payload = {
            equipe: '0',
            equipeDisplayedValue: '',
            //idUsuario: 104, //req.user.id
            taskName: 'Designar_Execucao',
            categoria: 'execucao',
            observacao: observacoes,
            candidatas: [
                codigoOS
            ],
            opcoesFluxo: { refazerVistoria: true }
        };
        return this.http.post(`${environment.OSPOA_API_URL}/${usuario}/os/despachar`,
            payload).pipe(
                catchError(this.handleError('marcarRefazerVistoria', { success: false }))
            );
    }

    confirmarPendenciasDeMateriais(usuario, dadosOS, payloadAnalise): Observable<any> {
        return this.http.post(`${environment.OSPOA_API_URL}/${usuario}/os/${dadosOS.OSCODIGO}/analisar`,
            payloadAnalise).pipe(
                catchError(this.handleError('confirmarPendenciasDeMateriais', { success: false }))
            );
    }
}
