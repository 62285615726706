import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

// libs
import { environment } from '@ospoa-workspace/core';

// app
import { AppModule } from './app/app.module';
import { KeycloakService } from '@ospoa-workspace/core/keycloak/services/keycloak.service';
import { keycloakEnvironment } from './app/core/environments/environment';

// if (environment.production) {
//   enableProdMode();
// }

// platformBrowserDynamic()
//   .bootstrapModule(AppModule)
//   .catch(err => console.log(err));

//Iniciar sistema COM KeyCloack
let tries = 0;

if (environment.production) {
    enableProdMode();
}

const init = () => {
    KeycloakService.init(keycloakEnvironment, { onLoad: 'login-required', checkLoginIframeInterval: 1000 })
        .then(() => {
            platformBrowserDynamic()
                .bootstrapModule(AppModule)
                .catch(err => console.log(err));
        })
        .catch((e) => {
            tries++;
            if (tries > 100) {
                console.log('Erro ao iniciar Keycloak', e);
            }
            else {
                init();
            }
        });
};

init();