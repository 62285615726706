import { Component } from '@angular/core';

import { BaseComponent } from '@ospoa-workspace/core';

@Component({
  selector: 'pgs-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss']
})
export class HeaderComponent extends BaseComponent {
  constructor() {
    super();
  }
}
