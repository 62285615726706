import { InjectionToken, Type } from '@angular/core';

/**
 * Various InjectionTokens shared across all platforms
 * Always suffix with 'Token' for clarity and consistency
 */

export const PlatformLanguageToken = new InjectionToken<string>(
  'PlatformLanguage'
);

export const RELATORIOS_PODAS_KC_ROLE = 'podas';
export const RELATORIOS_DEP_KC_ROLE = 'dep';
export const CONFIGURACOES_KC_ROLE = 'podas-administrador';
export const RELATORIOS_DCVU_KC_ROLE = 'vias-urbanas';
export const MAPA_USUARIO_KC_ROLE = 'mapa-usuario';