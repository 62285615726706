import { Injectable, ReflectiveInjector } from '@angular/core';
import { Observable, Observer } from 'rxjs/Rx';
import * as Keycloak from 'keycloak-js';
import { User } from "../models/usuario";
import { KeycloakServiceContract } from './keycloak.service.interface';

@Injectable()
export class KeycloakService implements KeycloakServiceContract {

    static _keycloakAuth: Keycloak;

    constructor() {
    }

    static init(environment?: { KEYCLOAK_URL: string, KEYCLOAK_REALM: string, KEYCLOAK_CLIENT: string }, options?: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this._keycloakAuth = new Keycloak({ url: environment.KEYCLOAK_URL, realm: environment.KEYCLOAK_REALM, clientId: environment.KEYCLOAK_CLIENT });

            this._keycloakAuth.init(options)
                .success(() => {
                    resolve();
                })
                .error((errorData: any) => {
                    console.error('KeycloakService - Error initializing Keycloak', errorData);
                    reject(errorData);
                });
        });
    }

    authenticated(): boolean {
        return KeycloakService._keycloakAuth.authenticated;
    }

    hasRole(roleName: string): boolean {
        return KeycloakService._keycloakAuth.hasResourceRole(roleName);
    }

    login() {
        KeycloakService._keycloakAuth.login();
    }

    logout() {
        KeycloakService._keycloakAuth.logout();
    }

    getToken(): Observable<string> {
        return Observable.create((obs: Observer<string>) => {
            this.getRefreshedData()
                .then(data => {
                    obs.next(data.token);
                    obs.complete();
                })
                .catch(error => {
                    obs.error(error);
                    obs.complete();
                });
        });
    }

    getUser(): User {
        const token = KeycloakService._keycloakAuth.tokenParsed;
        const idToken = token.hasOwnProperty('sid') ? token.sid : '';
        const u: User = {
            fullName: token.name,
            email: token.email,
            name: token.given_name,
            surname: token.family_name,
            username: token.preferred_username,
            idToken
        }
        return u;
    }


    private getRefreshedData(): Promise<Keycloak> {
        return new Promise<Keycloak>((resolve, reject) => {
            if (KeycloakService._keycloakAuth && KeycloakService._keycloakAuth.token) {
                KeycloakService._keycloakAuth
                    .updateToken(5)
                    .success(() => {
                        resolve(KeycloakService._keycloakAuth);
                    })
                    .error((error) => {
                        KeycloakService._keycloakAuth.clearToken();
                        reject(`KeycloakService - Unable to update access token ${error}`);
                    });
            } else {
                reject('KeycloakService - Not yet authenticated');
            }
        });
    }

    get keycloakAuth() {
        return KeycloakService._keycloakAuth;
    }
}
