import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, ActivatedRouteSnapshot, RouterStateSnapshot, Route } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { KeycloakService } from '../services/keycloak.service';

@Injectable()
export class KeycloakAuthGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(private keycloakService: KeycloakService) { }

  /** verifica a rota */
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkLogin(next.data);
  }

  /** verifica as rotas filhas */
  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkLogin(next.data);
  }
  /** verifica rotas lazy load */
  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkLogin(route.data);
  }

  private checkLogin(extras?: { roles?: string[] }): Observable<boolean> | Promise<boolean> | boolean {
    if (this.keycloakService.authenticated()) {
      if (extras && extras.roles) {
        let roles: string[];
        Array.isArray(extras.roles) ? roles = extras.roles : roles = [extras.roles];
        return roles.some(role => this.keycloakService.hasRole(role));
      }
      else {
        return true;
      }
    }
    else {
      this.keycloakService.login();
    }
  }
}
