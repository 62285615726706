import { LogService } from './log.service';
import { WindowService } from './window.service';
import { ConfiguracoesService } from './configuracoes.service';
import { OrdemServicoService } from './ordem-servico.service';
import { ArquivoService } from './arquivo.service';
import { RelatorioServicosDcvuService } from './relatorio-servicos-dcvu.service';

export const CORE_PROVIDERS: any[] = [LogService, WindowService,
    ConfiguracoesService,
    OrdemServicoService,
  ArquivoService,
  RelatorioServicosDcvuService];

export * from './log.service';
export * from './window.service';
export * from './tokens';
export * from './configuracoes.service';
export * from './ordem-servico.service';
export * from './arquivo.service';
export * from './relatorio-servicos-dcvu.service';

