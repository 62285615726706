import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/internal/operators/catchError';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../environments/environment';
import { BaseService } from '../base/base-service';

@Injectable()
export class ConfiguracoesService extends BaseService {
    constructor(private http: HttpClient) {
        super();
    }

    getTopicoEquipamentosPodas(): Observable<any> {
        return this.http.get(`${environment.OSPOA_API_URL}/os/classificacao/podas/topicosDeProdutividade?businessType=equipamentos`)
            .pipe(
                catchError(this.handleError('getTopicoEquipamentosPodas', {}))
            );
    }

    salvarTopicoPodas(dadosTopico): Observable<any> {
        return this.http.put(`${environment.OSPOA_API_URL}/os/classificacao/podas/topicosDeProdutividade/${dadosTopico._id}`,
            {
                updatedIn: dadosTopico.updatedIn,
                itensNovos: dadosTopico.items
            }).pipe(
                catchError(this.handleError('salvarTopicoPodas', false))
            );
    }
}
