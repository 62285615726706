import { NgModule } from '@angular/core';

// app
import { CoreModule } from './core/core.module';
import { SharedModule } from './features/shared/shared.module';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { HeaderComponent } from './features/shared/components';
import { KeycloakModule } from '@ospoa-workspace/core/keycloak/keycloak.module';

import { ToastrModule } from 'ngx-toastr';
// import { NgxMaskModule, IConfig } from 'ngx-mask'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// export const options: Partial<IConfig> | (() => Partial<IConfig>);

@NgModule({
    imports: [CoreModule, SharedModule, AppRoutingModule, BrowserAnimationsModule, ToastrModule.forRoot({
        positionClass: 'toast-top-center',
        maxOpened: 2,
        autoDismiss: true
    }), KeycloakModule.forRoot({ interceptor: null })/*, NgxMaskModule.forRoot()*/],
    declarations: [AppComponent, HeaderComponent],
    bootstrap: [AppComponent]
})
export class AppModule { }
