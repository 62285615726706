// angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// app
import { SharedModule } from './features/shared/shared.module';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full'
    },
    {
        path: 'home',
        loadChildren: './features/home/home.module#HomeModule'
    },
    {
        path: 'configuracoes',
        loadChildren: './features/configuracoes/configuracoes.module#ConfiguracoesModule'
    },
    {
        path: 'analiseos',
        loadChildren: './features/analise-os/analise-os.module#AnaliseOsModule'
    },
    {
        path: 'fotos',
        loadChildren: './features/fotos-156/fotos-156.module#Fotos156Module'
    },
    {
        path: 'relatorio-servicos-dcvu',
        loadChildren: './features/relatorio-servicos-dcvu/relatorio-servicos-dcvu.module#RelatorioServicosDcvuModule'
    }
];

@NgModule({
    imports: [SharedModule, RouterModule.forRoot(routes)]
})
export class AppRoutingModule { }
