import { Injectable } from '@angular/core';
import { BaseService } from '../base/base-service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class ArquivoService extends BaseService {
    constructor(private http: HttpClient) {
        super();
    }

    baixarArquivo(url): Observable<any> {
        return this.http.get(url, { responseType: 'blob' });
    }
}
