export const environment = {
  production: false,
  api_url: 'http://127.0.0.1:4000',
  baseRoutePath: '',
  OSPOA_API_URL: 'https://osurb-app-api.procempa.com.br',
  //   OSPOA_API_URL: 'http://localhost:3000',
  //   BASE_BPM_URL: 'https://api-hom.procempa.com.br/apiman-gateway/tser/bpm-api/1.2/bpm/osdeprep',
  //   BASE_BPM_URL_APIKEY: '737eee7c-de4a-440c-8de2-c5f7bc33141b',
  BASE_BPM_URL: 'https://apigateway.procempa.com.br/apiman-gateway/geo/bpm-api/1.0/bpm/osdep',
  BASE_BPM_URL_APIKEY: '459c751d-af14-4bbf-bc5a-0d4dbe1a91a2',
  BASE_WS156_URL: 'https://api.procempa.com.br/apiman-gateway/governoeletronico/ws156/1.0/rest/app/solicitacoes',
  BASE_WS156_URL_APIKEY: '336ee9ba-b6a7-466c-80f8-b717d1721dce'
};
